@import "variables";

ol {
  list-style-type: none;

  li {
    list-style: none;
  }
}

//Button
.euiButton[class*="fill-primary"] {
  background-color: var(--color-primary);
}

.euiButtonEmpty[class$="empty-primary"] {
  color: var(--color-primary);
}

button.critical-asset-button_compressed {
  height: var(--size-32);
}

.commonButtonHeight {
  height: var(--size-32);
}

.fullButtonHeight[type="button"] {
  height: var(--size-40);
}

button.fullButtonHeight.back-button[type="button"] {
  color: var(--color-primary);
  background-color: var(--color-primary-20);
  font-size: 14px;
  font-weight: 400;
}

button.fullButtonHeight.next-button[type="button"] {
  background-color: var(--color-primary);
  color: white;
  font-size: 14px;
  font-weight: 400;

  &[class*="isDisabled"] {
    background-color: var(--color-button-background-disabled);
    color: var(--color-disabled);
  }
}

//Link
.euiLink[class*="euiLink-primary"] {
  color: var(--color-primary);
}

//TextInput
.euiFieldText:focus,
.euiSuperSelectControl:focus,
.euiSuperSelectControl.euiSuperSelect--isOpen__button {
  background-image: linear-gradient(
    to top,
    var(--color-primary),
    var(--color-primary) 2px,
    transparent 2px,
    transparent 100%
  );
}

//HorizontalSteps
.euiStepHorizontal-isComplete .euiStepNumber--complete {
  color: white;
  background-color: var(--color-primary);
}

.euiStepHorizontal__item {
  .euiStepHorizontal.euiStepHorizontal-isSelected {
    &::before {
      background-color: var(--color-primary);
    }
  }

  .euiStepHorizontal.euiStepHorizontal-isComplete {
    &::after {
      background-color: var(--color-primary);
    }

    &::before {
      background-color: var(--color-primary);
    }
  }
}

.euiStepNumber.euiStepNumber--incomplete .euiStepNumber__number {
  top: 0;
}

.euiStepHorizontal-isSelected .euiStepNumber {
  color: black;
  background-color: white;
  border: 2px solid var(--color-primary);
}

.euiStepHorizontal__number {
  display: flex;
  justify-content: center;
  align-items: center;
}

//Combobox
.euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap {
  background-image: linear-gradient(
    to top,
    var(--color-primary),
    var(--color-primary) 2px,
    transparent 2px,
    transparent 100%
  );
}

.euiComboBox.euiComboBox-isInvalid .euiComboBox__inputWrap {
  background-image: linear-gradient(
    to top,
    var(--color-danger),
    var(--color-danger) 2px,
    transparent 2px,
    transparent 100%
  );
}

.euiFilterSelectItem {
  div.euiFlexItem {
    display: none;
  }
}

.euiBadge.euiComboBoxOption__enterBadge {
  visibility: hidden;
}

//SuperSelect
.euiFormControlLayout {
  .euiSuperSelectControl {
    & + .euiFormControlLayoutIcons {
      & > .euiIcon {
        display: none;
      }
    }
  }
  .euiFormControlLayoutIcons {
    & > .euiIcon {
      display: none;
    }
  }
}
.euiSuperSelect__listbox .euiContextMenuItem {
  .euiContextMenu__itemLayout svg {
    display: none;
  }
}

//
// div.euiFormControlLayout  input.euiFieldText {
//  height: var(--size-32);
//}

//Form
.euiFormRow__fieldWrapper {
  padding-bottom: var(--size-12);
  margin-bottom: -12px;

  &:has(> .euiFormErrorText) {
    padding-bottom: 0;
  }
}

.euiFormErrorText {
  &.euiFormRow__text {
    color: var(--color-danger);
    font-size: var(--size-10);
    line-height: var(--size-12);
    padding: var(--size-4) 0 0;
  }
}

.tree-wrapper .euiFormErrorText {
  &.euiFormRow__text {
    padding: 0;
  }
}

.euiFormRow.validated-control > .euiFormRow__fieldWrapper {
  margin-bottom: unset;
  position: relative;

  .euiFormErrorText {
    position: absolute;
    bottom: -26px;

    &.euiFormRow__text {
      padding: unset;
    }
  }

  .euiFormControlLayoutIcons.euiFormControlLayoutIcons--right {
    top: unset;
    bottom: 14px;
  }

  &:has(.euiFormErrorText) {
    margin-bottom: 12px;
  }
}

.euiFormRow.validated-control > .euiFormRow__fieldWrapper {
  .euiFormControlLayout--compressed
    .euiFormControlLayoutIcons.euiFormControlLayoutIcons--right {
    top: unset;
    bottom: 10px;
  }
}

.euiFormRow.validated-control.service-title > .euiFormRow__fieldWrapper {
  .euiFormErrorText {
    bottom: -14px;
  }
}

.euiFormRow.validated-control.view-edit-text > .euiFormRow__fieldWrapper {
  .euiFormErrorText {
    bottom: -14px;
  }
}

.euiMarkdownEditor.euiMarkdownEditor--isPreviewing {
  max-height: 500px;
}

//Table
.euiTable {
  .euiTableHeaderCell {
    .euiTableCellContent {
      color: var(--color-dark-shade);
    }
  }

  .euiTableRow {
    .euiTableRowCell {
      .euiTableCellContent {
        line-height: var(--size-24);
        color: var(--color-text);
        font-size: var(--size-14);
        font-weight: 400;
      }
    }
  }

  .euiTableFooterCell .euiTableCellContent__text,
  .euiTableHeaderCell .euiTableCellContent__text {
    line-height: var(--size-18);
    font-size: var(--size-12);
    font-weight: 600;
  }

  .euiTableHeaderButton-isSorted .euiTableSortIcon {
    fill: var(--color-dark-shade);
  }
}

//Modal
.euiModal {
  .euiButtonIcon.euiModal__closeIcon {
    right: var(--size-8);
    top: var(--size-8);
  }

  .euiModalFooter {
    .euiButton {
      &[class*="-fill-danger"] {
        background-color: rgba(#fd3d3d, 0.2);

        & .eui-textTruncate {
          color: var(--color-danger);
        }

        &:hover {
          background-color: var(--color-danger);

          & .eui-textTruncate {
            color: white;
          }
        }
      }
    }
  }
}

//Text
.euiText.placeholder {
  @extend .ellipsis;
  font-family: "Inter", sans-serif;
  max-width: 100%;
  padding-right: 40px;
  position: absolute;
  pointer-events: none;
  line-height: var(--size-32);
  color: var(--color-text-light);
  font-size: var(--size-14);
  top: var(--size-4);
  left: var(--size-12);

  &.disabled {
    color: var(--color-disabled);
  }

  &.labeled {
    top: unset;
    bottom: 4px;
  }

  &.compressed {
    top: 0;
  }
}

//Flyout

.euiFlyout .euiFlyoutBody .euiFlyoutBody__overflowContent {
  padding: 0;
}

//Custom

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  background: var(--color-tooltip-background);
  color: var(--color-text);
  border: none;
  font-weight: 400;
  font-size: var(--size-10);
  line-height: var(--size-12);
  text-align: center;
}

.tooltip-wrapper {
  padding: var(--size-10) !important;
  border: none !important;
  margin: 0 !important;
  background-color: var(--color-tooltip-background) !important;

  .euiToolTip__arrow {
    background-color: var(--color-tooltip-background) !important;
  }
}

.euiStepHorizontal__title {
  font-size: 16px;
  font-weight: 400;
}

.euiText.columnTitle {
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  padding-top: 24px;
  padding-bottom: 16px;
  color: var(--color-title);
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.tree-dropdown {
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  position: fixed;
  border-radius: 0 0 6px 6px;
  overflow: auto;
  z-index: 1000;
  padding: 16px 8px;
  max-height: 265px;
  left: 0;
  right: 0;
  max-width: 400px;
  background: white;
}

.tree-button {
  @extend .ellipsis;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.border-right-0 button.euiSuperSelectControl {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.border-left-0 div.euiComboBox__inputWrap {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.border-0 {
  margin-left: -1px;
  margin-right: -1px;

  div.euiComboBox__inputWrap {
    border-radius: 0;
  }
}

.euiFlyoutBody .euiFlyoutBody__overflow .euiFlyoutBody__overflowContent {
  padding: 0;
}

.work-order-footer {
  position: fixed;
  bottom: 0;
  padding: 20px;
  background: white;
}

.tree-wrapper .euiFormControlLayout__childrenWrapper input.euiFieldText {
  padding-right: 32px;
  @extend .ellipsis;
}

.menu-title {
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 28px;
}

.euiStepNumber__number {
  line-height: 21px;
  color: var(--color-step-number);
}

.euiStepHorizontal__title {
  line-height: 18px;
}

.euiStepHorizontal-isSelected .euiStepNumber {
  border-color: var(--color-primary);
}

.euiContextMenuItem__text {
  line-height: 21px;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.euiSuperSelect__item {
}

.euiModal--confirmation {
  svg {
    color: var(--color-danger);
  }

  .euiModal__closeIcon {
    display: none;
  }
}

.confirm-modal {
  .euiButtonEmpty {
    color: var(--color-text-medium);
  }
}

.critical-asset-modal_no-close-icon {
  .euiButtonIcon {
    display: none;
  }
}

.euiFlyoutBody .euiFlyoutBody__overflow {
  mask-image: none;
  -webkit-mask-image: none;
}

.euiFilterSelectItem__content .euiComboBoxOption__content {
  font-size: 14px;
}

.euiFlexGroup.compressed {
  height: 30px;
}

.work-order-header {
  margin-top: 8px;
}

.euiText.asset-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--color-title);
  margin-bottom: 16px;
}

.table {
  &-inner-scroll {
    thead {
      position: sticky;
      top: 0;
      background: white;
      z-index: 1;
    }
  }
}

#wo-add-asset-plans-table,
#wo-add-asset-plans-table {
  .euiAccordion__triggerWrapper {
    padding-bottom: 8px;
  }
}

.add-asset.euiFlyoutBody {
  overflow-x: hidden;
  margin-right: 20px !important;

  .euiFlyoutBody__overflow {
    padding-right: 20px;
    margin-right: -5px;
  }
}

.add-asset.euiFlyoutHeader {
  .euiText {
    color: var(--color-title);
  }
}

.euiToast.euiGlobalToastListItem {
  &[class*="danger"] {
    border-color: var(--color-danger);
  }

  .euiText {
    padding-right: 20px;
  }
}

.euiFormControlLayout {
  .euiFormControlLayoutIcons svg.euiIcon[class*="danger"] {
    color: var(--color-danger);
    margin-right: 6px;
  }

  button.euiSuperSelectControl-isInvalid,
  .euiTextArea:invalid,
  .euiFieldText:invalid {
    background-image: linear-gradient(
      to top,
      var(--color-danger),
      var(--color-danger) 2px,
      transparent 2px,
      transparent 100%
    );
  }
}

.euiFieldText.euiFieldText-isInvalid,
.euiTextArea:invalid {
  background-image: linear-gradient(
    to top,
    var(--color-danger),
    var(--color-danger) 2px,
    transparent 2px,
    transparent 100%
  );
}

.borderless .euiFieldText {
  box-shadow: none;
}

button.button-attachments,
button.button-asset {
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 18px;
  border-radius: 6px;
  background-color: var(--color-hover);
  color: var(--color-text-light);

  svg {
    fill: var(--color-text-light);
  }

  &:hover {
    background-color: var(--color-primary-20);

    svg {
      fill: var(--color-primary);
    }

    .euiText {
      color: var(--color-primary) !important;
      text-decoration: none !important;
    }
  }
}

.section-item {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: var(--color-text);
}

.none .section-item {
  color: var(--color-text-light);
}

.euiFlexItem.reporter {
  & > .euiText {
    color: var(--color-title) !important;
    display: flex !important;
    align-items: center;
    gap: 8px;

    &:after {
      display: block;
      content: "";
      background-image: url("../images/reporter.svg");
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
    }
  }
}

.modal-warning-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(105, 112, 125, 0.5);
  display: grid;
  z-index: 9999;

  .modal-warning-container {
    position: absolute;
    top: 100px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    padding: 24px;
    border-radius: 8px;
    background: white;
    width: 485px;

    .modal-warning-header {
      display: flex;
      align-items: end;
      gap: 16px;
      color: var(--color-title);
      font-size: 16px;
      line-height: 21px;
      font-weight: bold;

      &:before {
        display: block;
        content: "";
        background-image: url("../images/alert.svg");
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
      }
    }

    .modal-warning-message {
      color: var(--color-title);
      font-size: 16px;
      line-height: 21px;
      font-weight: bold;
      margin-top: 24px;
    }

    .modal-warning-footer {
      display: flex;
      justify-content: flex-end;
      gap: 24px;

      .button-cancel {
        background: none;
        outline: none;
        box-shadow: none;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: var(--color-title);
        height: 40px;
      }

      .button-ok {
        height: 40px;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: var(--color-text-button-ok);
        background: var(--color-background-button-ok);
        border-radius: 6px;
        padding: 0 24px;
      }
    }
  }
}

.euiComboBox .euiFormControlLayout {
  box-sizing: content-box;
}

.create-work-order-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: var(--color-primary-20);
  color: var(--color-primary);
  border-radius: 6px;
}

.euiPagination__item::marker {
  display: none !important;
}

//GRID
.euiDataGridHeaderCell__content {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text-light);
}

.euiDataGridRowCell__truncate {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-text);
}

.key-link {
  color: var(--color-primary);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

ul.euiPagination__list {
  list-style: none;
}

.work-orders-table {
  &__severity {
    border: 1px solid;
    border-radius: 4px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    width: fit-content;

    &_low {
      color: var(--color-primary);
    }

    &_moderate {
      color: var(--color-yellow);
      background: rgba(255, 189, 41, 0.1);
    }

    &_high {
      color: rgba(253, 61, 61, 0.6);
      background: rgba(253, 61, 61, 0.1);
    }

    &_critical {
      color: rgba(253, 61, 61, 0.7);
      background: rgba(253, 61, 61, 0.2);
    }
  }

  &__no-data {
    position: absolute;
    top: 140px;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: black;
    z-index: 9;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.euiDataGrid--rowHoverHighlight .euiDataGridRow:hover {
  background: rgba(69, 155, 254, 0.1);
}

// grid height fix
.euiDataGrid {
  .euiDataGrid__content {
    height: auto;
    background: white;
    flex-grow: 0;

    .euiDataGrid__virtualized {
      height: 100% !important;
    }
  }
}

// 2-states date picker
.view-edit-date-picker
  .euiDatePicker
  .euiPopover:not(.euiPopover-isOpen)
  + .euiFormControlLayoutIcons {
  display: none;
}

.view-edit-date-picker
  .euiDatePicker
  .euiPopover:not(.euiPopover-isOpen)
  input {
  background: white;
  border: none;
  box-shadow: none;
  padding-left: 0;
  cursor: pointer;

  &:hover {
    background: var(--color-hover);
  }
}

.edit-section-footer {
  margin-top: -8px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;

  button {
    color: var(--color-text-light);
    background: var(--color-hover);

    &:disabled {
      background: rgba(171, 180, 196, 0.1);
      color: #9ba5b7;
    }
  }
}

.euiAvatar {
  span {
    color: white;
  }
}
