:root {
  --color-primary: #459bfe;
  --color-primary-20: rgba(69, 155, 254, 0.2);
  --color-danger: #fd3d3d;
  --color-text-light: #69707d;
  --color-text-medium: #343741;
  --color-title: #1a1c21;
  --color-hover: #f5f7fa;
  --color-dark-shade: #69707d;
  --color-text: #343741;
  --color-darkest-shade: #343741;
  --color-control-background: #fbfcfd;
  --color-disabled: #abb4c4;
  --color-tooltip-background: #eef2f7;
  --color-step-number: #343741;
  --color-button-background-disabled: rgba(171, 180, 196, 0.1);
  --color-text-button-ok: #8c5f00;
  --color-background-button-ok: rgba(254, 197, 20, 0.2);
  --color-yellow: #ffbd29;

  --size-135: 135px;
  --size-40: 40px;
  --size-32: 32px;
  --size-24: 24px;
  --size-18: 18px;
  --size-17: 17px;
  --size-14: 14px;
  --size-12: 12px;
  --size-10: 10px;
  --size-8: 8px;
  --size-6: 6px;
  --size-4: 4px;
}
