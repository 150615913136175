$out-of-range-color: #cacaca;
$out-of-range-bg-color: #fbfbfb;

$calendar-border: #e5e5e5;
$cell-border: #e5e5e5;
$border-color: #e5e5e5;

$time-selection-color: white;
$time-selection-bg-color: rgba(0, 0, 0, 0.5);
$date-selection-bg-color: rgba(0, 0, 0, 0.1);

$event-bg: #459bfe;
$event-border: darken(#459bfe, 10%);
$event-outline: #459bfe;
$event-color: #ffffff;
$event-border-radius: 0;
$event-padding: 0 2px;

$btn-color: #444444;
$btn-bg: #ffffff;
$btn-border: #cacaca;

$current-time-color: #f7f9fc;
$today-highlight-bg: #f7f9fc;

@import "react-big-calendar/lib/sass/styles";

.rbc-header {
  font-size: 0.65rem;
  font-weight: 400;
  text-transform: uppercase;
}

.rbc-event,
.rbc-selected {
  background-color: transparent !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  border: none !important;
}

.rbc-event-content {
  font-size: 0.75rem;
}

.rbc-btn-group {
  button {
    border-radius: 0;
  }
}

.rbc-toolbar .rbc-btn-group:first-child {
  display: inline-flex;

  button {
    &:first-of-type {
      order: 1;
    }

    &:not(:first-child) {
      padding-bottom: 0.55rem;
      padding-top: 0.2rem;
      font-size: 1.5rem;
      line-height: 1.35rem;
    }
  }
}

@media (max-width: 48rem) {
  .rbc-toolbar {
    flex-direction: column;

    &-label {
      margin: 0.5rem 0;
    }
  }
}
